import { useAuth0 } from '@auth0/auth0-react'
import { useState, useEffect } from 'react'
import { getProtectedResource } from '../auth/auth-fetch'

export const useCamera = () => {
    const URL_BACK = process.env.REACT_APP_URL_BACK
    const AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE
    const { user, getAccessTokenSilently } = useAuth0()
    const [cameras, setCameras] = useState()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    useEffect(() => {
        (async () => {
            try {
                const token = await getAccessTokenSilently({
                    authorizationParams: {
                        audience: AUDIENCE,
                    },
                    cacheMode: "on"
                })
                const urlFetch = `${URL_BACK}/api/cameras/${user?.sub}`
                const { data } = await getProtectedResource(urlFetch, token)
                setCameras(data)
                setLoading(false)
            } catch (error) {
                console.error(error);
                setError(error.response.data.error)
                setLoading(false)
            }
        })();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    return { cameras, loading, error }
}
