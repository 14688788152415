import axios from 'axios'

export const getProtectedResource = async (endpoint, accessToken) => {
    // const apiServerUrl = process.env.REACT_APP_URL_BACK;
    const config = {
        url: `${endpoint}`,
        method: "GET",
        responseEncodig: 'utf-8',
        headers: {
            "content-type": "application/json",
            authorization: `Bearer ${accessToken}`,
            'cache-control': 'no-cache'
        },
    };
    const { data, error } = await axios(config);
    return {
        data: data || null,
        error: error || null
    };
};

export const postProtectedResource = async (endpoint, accessToken, requestBody) => {
    // const apiServerUrl = process.env.REACT_APP_URL_BACK;
    const config = {
        url: `${endpoint}`,
        method: "POST",
        responseEncoding: 'utf-8',
        headers: {
            "content-type": "application/json",
            authorization: `Bearer ${accessToken}`,
            'cache-control': 'no-cache'
        },
        data: requestBody,
    };

    try {
        const response = await axios(config);
        return {
            data: response.data || null,
            error: null
        };
    } catch (error) {
        return {
            data: null,
            error: error.response.data || "Something went wrong"
        };
    }
};

// // export const getAdminResource = async (accessToken) => {
// //     const config = {
// //         url: `${apiServerUrl}/api/messages/admin`,
// //         method: "GET",
// //         headers: {
// //             "content-type": "application/json",
// //             Authorization: `Bearer ${accessToken}`,
// //         },
// //     };

// //     const { data, error } = await callExternalApi({ config });

// //     return {
// //         data: data || null,
// //         error,
// //     };
// // };