import { useData } from "../../hooks/useData"
import { useMemo, useState } from "react"
import {
  keepPreviousData,
  useInfiniteQuery
} from "@tanstack/react-query";
import moment from "moment/moment"
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { Box, Text } from "@chakra-ui/react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Tooltip from "@mui/material/Tooltip";

const tableProps = {
  muiTableBodyCellProps: {
    align: "center",
    sx: {
      height: "10px",
      textAlign: "center",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      borderRight: "1px solid #e0e0e0",
    },
  },
  muiTableHeadCellProps: {
    //align: "center",
    sx: {
      height: "40px",
      borderRight: "1px solid #e0e0e0",
      fontSize: "12px",
      color: "rgb(74, 85, 104)",
    },
  }
}

const initialState = {
  showColumnFilters: false,
  sorting: [{ id: "created_at", desc: true }],
};

const LogsTable = () => {
  const {
    fetchLogs,
    optionsDevice,
    optionsModels,
    optionsServCompany,
    optionsLocations,
  } = useData()

  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([{ id: "created_at", desc: true }]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 30,
  });

  const {
    data,
    isLoading,
    isRefetching,
    isError,
  } = useInfiniteQuery({
    queryKey: ["/api/log-filters/", columnFilters, pagination.pageIndex, pagination.pageSize, sorting],
    queryFn: () => fetchLogs({ pagination, columnFilters, sorting }),
    initialPageParam: 1,
    keepPreviousData: true,
    getNextPageParam: (page) => page.currentPage === page.lastPage ? undefined : page?.pageIndex +1,
    getPreviousPageParam: (page) => page.currentPage === page.lastPage ? undefined : page?.current +1,
    placeholderData: keepPreviousData,
  })

  const columns = useMemo(
    () => [
      {
        header: "DATE",
        accessorFn: (originalRow) => new Date(originalRow.created_at),
        Cell: ({ renderedCellValue }) => (
          <Text m={0}>{moment(renderedCellValue).format("YYYY-MM-DD HH:mm")}</Text>
        ),
        filterVariant: "date-range",
        minSize: 150,
        autoSize: true,
        ...tableProps
      },
      {
        header: "ALARM NAME",
        accessorFn: (originalRow) => originalRow?.alarm_name?.toUpperCase() || "-",
        accessorKey: "alarm_name",
        filterVariant: 'text',
        minSize: 300,
        ...tableProps,
      },
      {
        header: "PROBLEM",
        accessorKey: "problem",
        accessorFn: (originalRow) => originalRow.problem.toUpperCase(),
        filterVariant: 'text',
        grow: true,
        Cell: ({ renderedCellValue }) => (
          <Tooltip title={renderedCellValue}>
            <Box
              sx={{
                height: "20px",
                textAlign: "left",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {renderedCellValue}
            </Box>
          </Tooltip>
        ),
        muiTableBodyCellProps: {
          sx: {
            height: "10px",
            textAlign: "left",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            borderRight: "1px solid #e0e0e0",
          },
        },
        muiTableHeadCellProps: {
          // align: "center",
          sx: {
            height: "40px",
            borderRight: "1px solid #e0e0e0",
            fontSize: "12px",
            color: "rgb(74, 85, 104)",
          },
        },
        // minSize: 200,
      },
      {
        header: "SEVERITY",
        accessorKey: "severity",
        accessorFn: (dataRow) => dataRow?.severity === 1 ? "HIGH" : dataRow?.severity === 2 ? "MEDIUM" : "LOW",
        Cell: ({ renderedCellValue }) => (
          <Box justifyContent="center" alignItems="center" width="100%">
            <Box bg={renderedCellValue === "HIGH" ? "#F7DCE2" : renderedCellValue === "MEDIUM" ? "#F9E6CE" : "#FAF6D3"} borderRadius="8px" textAlign="center" padding="2px" color="#2A4365">
              <Text p={0} m={0}>{renderedCellValue}</Text>
            </Box>
          </Box>
        ),
        filterVariant: "multi-select",
        filterSelectOptions: ["HIGH", "MEDIUM", "LOW"],
        minSize: 150,
        ...tableProps,
      },
      {
        header: "STATUS",
        accessorKey: "status",
        accessorFn: (dataRow) => dataRow?.closed_at ? "CLOSED" : "OPEN",
        Cell: ({ renderedCellValue }) => (
          <Box justifyContent="center" alignItems="center" width="100%">
            <Box bg={renderedCellValue === "OPEN" ? "#B0EFE3" : "#FAD9C1"} borderRadius="8px" textAlign="center" padding="2px" color="#2A4365">
              <Text p={0} m={0}>{renderedCellValue}</Text>
            </Box>
          </Box>
        ),
        filterVariant: "select",
        filterSelectOptions: ["Open", "Closed"],
        // minSize: 200,
        autoSize: true,
        ...tableProps,
      },
      {
        header: "DURATION",
        accessorFn: (dataRow) => {
          const realClosedAt = dataRow.closed_at ? dataRow.closed_at : moment();
          const dayDiff = moment(realClosedAt).diff(moment(dataRow.created_at), "days");
          if (dayDiff > 0) return `${dayDiff} days`;
          const hourDiff = moment(realClosedAt).diff(moment(dataRow.created_at), "hours");
          if (hourDiff > 0) return `${hourDiff} hours`;
          const minutesDiff = moment(realClosedAt).diff(moment(dataRow.created_at), "minutes");
          return `${minutesDiff} minutes`;
        },
        enableColumnFilter: false,
        // minSize: 200,
        autoSize: true,
        ...tableProps,
      },
      {
        header: "DEVICE",
        accessorKey: "serial",
        filterVariant: "multi-select",
        filterSelectOptions: optionsDevice || [],
        Cell: ({ renderedCellValue }) => (
          <Box justifyContent="center" alignItems="center" width="100%">
            <Box bg="#BEE3F8" borderRadius="8px" textAlign="center" padding="2px" color="#2A4365">
              <Text p={0} m={0}>{renderedCellValue}</Text>
            </Box>
          </Box>
        ),
        // minSize: 200,
        autoSize: true,
        ...tableProps,
      },
      {
        header: "MODEL",
        accessorKey: "device_model",
        filterVariant: "multi-select",
        filterSelectOptions: optionsModels || [],
        // minSize: 200,
        autoSize: true,
        ...tableProps,
      },
      {
        header: "LOCATION",
        accessorKey: "location",
        accessorFn: (dataRow) => dataRow?.service_info?.name?.toUpperCase() || "-",
        filterVariant: "multi-select",
        filterSelectOptions: optionsLocations || [],
        minSize: 250,
        autoSize: true,
        ...tableProps,
      },
      {
        header: "SERVICE COMPANY",
        accessorKey: "service_company",
        accessorFn: (dataRow) => dataRow?.service_info?.serviceAccount?.name?.toUpperCase() || "-",
        filterVariant: "multi-select",
        filterSelectOptions: optionsServCompany || [],
        minSize: 300,
        ...tableProps,
      },
      {
        header: "OPERATOR COMPANY",
        accessorKey: "operator_company",
        accessorFn: (dataRow) => dataRow?.service_info?.operatorAccount?.name?.toUpperCase() || "-",
        filterVariant: "multi-select",
        filterSelectOptions: optionsServCompany || [],
        minSize: 300,
        ...tableProps,
      },
      {
        header: "OWNER",
        accessorKey: "account_creator",
        accessorFn: (dataRow) => dataRow?.service_info?.accountCreator?.name?.toUpperCase() || "-",
        filterVariant: "multi-select",
        filterSelectOptions: optionsServCompany || [],
        minSize: 300,
        ...tableProps,
      },
      {
        header: "SOURCE",
        accessorKey: "source",
        accessorFn: (dataRow) => dataRow?.alarm_rule_id >= 1000 && dataRow?.alarm_rule_id <= 2000 ? "Assistant" : "mSuite",
        filterVariant: "multi-select",
        filterSelectOptions:  ["Assistant", "mSuite"],
        minSize: 300,
        ...tableProps,
      },
      {
        header: "VIEWER",
        accessorKey: "viewer",
        filterVariant: "multi-select",
        filterSelectOptions:  [],
        minSize: 300,
        ...tableProps,
      },
      {
        header: "SEEN DATE",
        accessorKey: "seen_date",
        filterVariant: "multi-select",
        filterSelectOptions:  [],
        minSize: 300,
        ...tableProps,
      }
    ],
    [optionsDevice, optionsModels, optionsLocations, optionsServCompany],
  );

  const table = useMaterialReactTable({
    columns,
    data: data?.pages[0]?.events || [],
    initialState,
    enableTopToolbar: false,
    muiPaginationProps: {
      color: "primary",
      shape: "rounded",
      showRowsPerPage: false,
      variant: "outlined"
    },
    paginationDisplayMode: "pages",
    muiToolbarAlertBannerProps: isError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    manualPagination: true,
    onColumnFiltersChange: setColumnFilters,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount: data?.pages[0]?.totalEvents ?? 0,
    columnFilterDisplayMode: "popover",
    rowVirtualizerOptions: { overscan: 5 },
    columnVirtualizerOptions: { overscan: 4 },
    enableColumnPinning: true,
    enableColumnResizing: true,
    state: {
      columnFilters,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        borderRadius: "0",
      },
    },
    muiTableBodyProps: {
      sx: (theme) => ({
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
          {
            backgroundColor: "#F7FAFC",
          },
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          {
            backgroundColor: "#F7FAFC",
          },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
          {
            backgroundColor: "#FFFFFF",
          },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          {
            backgroundColor: "#F7FAFC",
          },
      }),
    },
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MaterialReactTable
        table={table}
      />
    </LocalizationProvider>
  );
}

export default LogsTable;